<template>
<div style="width:100%;background:#fff;">
    <div class="help-main">
        <div class="help-menu">
            <div class="help-title">帮助中心</div>
            <div class="help-list">
                <router-link class="help-item" :to="item.path" v-for="(item,index) in menuList" :key="index">{{item.name}}</router-link>
            </div>
        </div>
        <div class="help-router">
            <router-view></router-view>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "App",
    data() {
        return {
            menuList: [{
                    name: "会员注册",
                    path: "/helpCenter/helpRegister",
                },
                {
                    name: "会员登录",
                    path: "/helpCenter/helpLogin",
                },
                {
                    name: "找回密码",
                    path: "/helpCenter/helpPassword",
                },
                {
                    name: "账号注销",
                    path: "/helpCenter/helpcancellation",
                },
                {
                    name: "赚海贝",
                    path: "/helpCenter/helpGet",
                },
                {
                    name: "花海贝",
                    path: "/helpCenter/helpPay",
                },
                {
                    name: "兑换流程",
                    path: "/helpCenter/helpFlow",
                },
                {
                    name: "兑换规则",
                    path: "/helpCenter/helpRule",
                },
                {
                    name: "海贝计划",
                    path: "/helpCenter/helpPlan",
                },
                {
                    name: "交易条款",
                    path: "/helpCenter/helpClause",
                },
                {
                    name: "常见问题",
                    path: "/helpCenter/helpQuestion",
                },
                {
                    name: "关于我们",
                    path: "/helpCenter/helpAboutUs",
                },
                {
                    name: "联系方式",
                    path: "/helpCenter/helpContact",
                },
            ],
        };
    },

    methods: {},

    mounted() {},
};
</script>

<style lang="scss" scoped>
.help-main {
    padding-top: 20px;
    width: 1200px;
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: row;

    .help-menu {
        width: 190px;
        height: 570px;
        background: #fff;
        margin-right: 20px;

        .help-title {
            width: 190px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            background: #AC8C4F;
            font-size: 14px;
            color: #fff;
        }

        .help-list {
            display: flex;
            flex-direction: column;
            margin-top: 10px;

            .help-item {
                text-align: center;
                height: 40px;
                font-size: 14px;
                transition: color 0.1s linear;
                line-height: 40px;
                // font-weight: bold;
            }

            .help-item:hover {
                color: #AC8C4F;
                transition: color 0.1s linear;
            }
        }
    }

    .help-router {
        padding: 20px;
        background: #fff;
        flex: 1;
        height: auto;
    }
}

.router-link-active {
    color: #AC8C4F;
}
</style>
